import {
  Row,
  Col,
  Card,
  Table,
  Avatar,
  Typography,
  Button,
  Popover
} from "antd";
import { PlusOutlined,EditTwoTone } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getVendorList } from "../redux/actions/vendorActions/index";
import EditVendorDrawer from "../components/EditVendorDrawer";
import { Input, Space } from 'antd';

const { Title } = Typography;
const { Search } = Input;


function Vendor() {
  
   // Control drawer
   const [isEditableDrawerOpen, setIsEditableDrawerOpen] = useState(false);
   const [particularVendorInformation, setParticularVendorInformation] =
     useState({});

  // Fetch data
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorList({}));
  }, [dispatch]);

  

  const { vendors = [], fetching } = useSelector(({ vendor }) => vendor);
  const onRowClick = (vendorInformation) => {
    setIsEditableDrawerOpen(true);
    setParticularVendorInformation(vendorInformation);
  };

  const onSearch = (value) => {
    const filterdata = { "filters": { "name__icontains": value } }
    dispatch(getVendorList(filterdata));
  }

  const reloadVendors = () => {
    dispatch(getVendorList({}));
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, record) => (
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="round"
            size={30}
            src={record.img}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5} ><Space>{data}<Popover content="Edit Details"><EditTwoTone onClick={() => onRowClick(record)} /></Popover></Space></Title>
            <p>{record.company_name}</p>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "Contact Information",
      dataIndex: "phone",
      key: "phone",
      render: (data, record) => (
        <>
          <div>{data}</div>
          <div>{record.email}</div>
          <div>{record.website}</div>
        </>
      ),
    },
    {
      title: "Region",
      dataIndex: "country",
      key: "country",
      // align: "center",
      render: (data, record) => (
        <>
          <div>{data}</div>
          <div>{record.city}</div>
        </>
      ),
    },
    {
      title: "Added on",
      dataIndex: "created_at",
      key: "created_at",
      // width: "110px",
      render: (data, record) => (
        <>
          <div>{data}</div>
          <div>{record.updated_at}</div>
        </>
      ),
    },
   
  ];

  return (
    <>
      <div className="tabled">
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title="Vendors Details"
          extra={
            <Button
             type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ color: 'white', zIndex: 10 }}
              onClick={onRowClick}
            >
              Add Vendor
            </Button>
          }
        >
           <Row >
            <Col span={8}>
              <div style={{ marginLeft: '5%' }}>
                <Search placeholder="Search By Name" onSearch={onSearch} enterButton />
              </div>
            </Col>
          </Row>
          
          <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={vendors}
              pagination={{ pageSize: 50, }}
              loading={fetching}
              rowKey={(record) => record.id}
              className="ant-border-space"
            />
          </div>
        </Card>
      </div>
      {isEditableDrawerOpen && (
        <EditVendorDrawer
          isEditableDrawerOpen={isEditableDrawerOpen}
          setIsEditableDrawerOpen={setIsEditableDrawerOpen}
          particularVendorInformation={particularVendorInformation}
          setParticularVendorInformation={setParticularVendorInformation}
          reloadVendors={reloadVendors}
        />
      )}
    </>
  );
}

export default Vendor;


