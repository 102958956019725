import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveSurveyData } from "../../redux/actions/surveyActions";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const EditSurveyDrawer = ({
  setIsEditableDrawerOpen,
  particularSurveyInformation,
  reloadSurveys
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onSaveSuccess = () => {
    setIsEditableDrawerOpen(false);
    reloadSurveys();
  }

  const { filterData = {} } = useSelector(({ survey }) => survey);
  console.log("filterData:", filterData)
  console.log("particularSurveyInformation:", particularSurveyInformation)

  const onFinish = (value) => {
    console.log(value);
    value.start_date = value.dateRange[0].format("YYYY-MM-DD")
    value.end_date = value.dateRange[1].format("YYYY-MM-DD")
    value.other = {}
    value.other_redirects = {}
    value.profile_condition = {}
    // setIsEditableDrawerOpen(false);
    dispatch(saveSurveyData(value, onSaveSuccess));
  };
  useEffect(() => {
    if (Object.keys(particularSurveyInformation).length) {
      particularSurveyInformation.dateRange = [moment(particularSurveyInformation.start_date, "DD MMM YY, h:mm:ss"),
                                               moment(particularSurveyInformation.end_date, "DD MMM YY, h:mm:ss")]
      form.setFieldsValue(particularSurveyInformation);
    }
  }, [particularSurveyInformation, form]);
  const onClose = () => {
    setIsEditableDrawerOpen(false);
  };

  const isAdd = JSON.stringify(particularSurveyInformation) === '{}';

  return (
    <>
      <Drawer
        title={isAdd ? "Add New Survey Campaign" : "Edit Survey Campaign"}
        width={720}
        closable={false}
        onClose={onClose}
        visible={true}
        placement="right"
        style={{ position: "fixed", right: 0 }}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button form="survey_submit_form" key="submit" htmlType="submit" type="primary">
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form
          id="survey_submit_form"
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          initialValues={{
            name: particularSurveyInformation.name,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="id" label="Unique ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="name" label="Name">
                <Input placeholder="Please Enter Survey Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Current status of survey",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Please select an status"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={filterData.status.campaign.map(option => { return { value: option, label: option } })}
                >
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Category Type"
                rules={[
                  {
                    required: true,
                    message: "Please choose the type",
                  },
                ]}
              >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Please Select Category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={Object.keys(filterData.profile_category).map(option => { return { value: option, label: option } })}
                  >
                  </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="respondent_currency" label="Respondent currency">
                <Select
                  size="large"
                  showSearch
                  placeholder="Please Select Currency"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={["INR", "USD"].map(option => { return { value: option, label: option } })}
                >
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="respondent_cpi"
                label="Respondent CPI"
                rules={[
                  {
                    required: true,
                    message: "Please provide Respondent CPI",
                  },
                ]}
                >
                <Input></Input>
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={16}>
            <Col span={24}>
            <Form.Item name="dateRange" label="Start and End date">
                <RangePicker
                  size="large"
                  format={"DD MMM YY"}
                  style={{width: "100%",}}
                  />
              </Form.Item>
            </Col>
          </Row>
          <Divider>User Interface Information</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="ui_title" label="UI Title">
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ui_icon"
                label="UI Icon URL"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="ui_description"
                label="Description"
              >
                <Input style={{width: "100%",}}/>
              </Form.Item>
            </Col>

          </Row>
          

          <Divider>Client Information</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="client_id" label="Client">
                <Select
                  size="large"
                  showSearch
                  placeholder="Please select an client"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={filterData.clients.map(option => { return { value: option.id, label: option.name } })}
                >
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
                name="client_currency"
                label="Client Currency"
                rules={[
                  {
                    required: true,

                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Please Select Currency"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={["INR", "USD"].map(option => { return { value: option, label: option } })}
                >
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
            <Form.Item
                name="client_live_url"
                label="Client Live URL"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}

                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="client_test_url"
                label="Client Test URL"
                rules={[
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}

                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
            <Form.Item name="client_cpi" label="Client CPI">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="client_completed_count" label="Client Completed Count">
                <Input defaultValue="0" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="client_partial_count" label="Client Partial Count">
                <Input defaultValue="0" />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Vendor Information</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="vendor_id" label="Vendor ID">
                <Select
                  size="large"
                  showSearch
                  placeholder="Please select vendor"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={filterData.vendors.map(option => { return { value: option.id, label: option.name } })}
                >
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vendor_cpi"
                label="Vendor CPI"
                rules={[
                  {
                    required: true,

                  },
                ]}
              >
                <Input defaultValue="0"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="vendor_ir" label="Vendor IR">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vendor_currency"
                label="Vendor Currency"
                rules={[
                  {
                    required: true,
                    message: "Current status of survey",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Please Select Currency"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={["INR", "USD"].map(option => { return { value: option, label: option } })}
                >
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="vendor_loi" label="Vendor LOI">
                <Input defaultValue="0"/>
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="complete_redirect"
                label="Complete Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="quota_full_redirect"
                label="Quota Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input


                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="terminate_redirect"
                label="Terminate Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input



                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="security_redirect"
                label="Security Redirect Link"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Drawer>
    </>
  );
};
export default EditSurveyDrawer;