
import {
  Drawer,
  Descriptions,
  Button,
} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { invoiceClient } from "../../redux/actions/surveyActions";




const InvoiceClientDrawer = ({
  isInvoiceClientDrawerOpen,
  setIsInvoiceClientDrawerOpen,
  }) => {

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(invoiceClient(1));
  }, [dispatch]);

  const { clientInvoice = {}, fetching } = useSelector(({ survey }) => survey);
  console.log(invoiceClient);

  const onClose = () => {
    setIsInvoiceClientDrawerOpen(false);
  };

 

  return (<>
    <Drawer
      title="Invoice Client"
      width={720}
      closable={false}
      onClose={onClose}
      visible={true}
      placement="right"
      style={{ position: "fixed", right: 0 }}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        
          
          <Button onClick={onClose}>Close</Button>
        
      }
     
    >
      
      <Descriptions
      title="Client Invoice Details"
      bordered
      column={{
        xxl: 1,
        xl: 1,
        lg: 1,
        md: 1,
        sm: 1,
        xs: 1,
      }}
    >
      <Descriptions.Item label="Captured Count">{clientInvoice.captured_count}</Descriptions.Item>
      <Descriptions.Item label="CPI">{clientInvoice.cpi}</Descriptions.Item>
      <Descriptions.Item label="Count">{clientInvoice.count}</Descriptions.Item>
      <Descriptions.Item label="Currency">{clientInvoice.currency}</Descriptions.Item>
      <Descriptions.Item label="Tax">$20.00</Descriptions.Item>
      <Descriptions.Item label="Total Amount">{clientInvoice.total}</Descriptions.Item>
      
    </Descriptions>


            
    </Drawer>

  </>)
};
export default InvoiceClientDrawer;


