import { Row, Col, Card, Table, Avatar, Typography, Button,Input,Space, Popover, Badge} from "antd";
import { useDispatch } from "react-redux";
import React, { useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { getFilterData, getSurveyList } from "../redux/actions/surveyActions/index";
// import { Anchor } from "antd";
import EditSurveyDrawer from "../components/EditSurveyDrawer/index.js";
import CampaignDrawer from "../components/FilterForCampaingDrawer/index";
import InvoiceClientDrawer from "../components/InvoiceClientDrawer";

import { EditTwoTone,FilterTwoTone, PlusOutlined,UpSquareTwoTone,DownSquareTwoTone } from '@ant-design/icons';
import InvoiceVendorDrawer from "../components/InvoiceVendorDrawer";

// import Highlighter from 'react-highlight-words';

const { Title } = Typography;
const { Search } = Input;

function SurveyCampaign() {
  // Control drawer
  const [isEditableDrawerOpen, setIsEditableDrawerOpen] = useState(false);
  const [isInvoiceClientDrawerOpen, setIsInvoiceClientDrawerOpen] = useState(false);
  const [isInvoiceVendorDrawerOpen, setIsInvoiceVendorDrawerOpen] = useState(false);
  const [isCampaignDrawerOpen, setIsCampaignDrawerOpen] = useState(false);
  const [particularSurveyInformation, setParticularSurveyInformation] =
    useState({});
  const [particularCampaignInformation, setParticularCampaignInformation] =
    useState({});
  

  // Fetch data
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSurveyList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFilterData({}));
  }, [dispatch]);

  const { surveys = [], fetching } = useSelector(({ survey }) => survey);

  const reloadSurveys = () => {
    dispatch(getSurveyList({}));
  }

  const onEditClick = (surveyInformation) => {
    setIsEditableDrawerOpen(true);
    setParticularSurveyInformation(surveyInformation);
  };

  const onAddClick = (surveyInformation) => {
    setIsEditableDrawerOpen(true);
    setParticularSurveyInformation({});
  };

  const onFilterClick = (campaignInformation) => {
    setIsCampaignDrawerOpen(true);
    setParticularCampaignInformation(campaignInformation);
  };

  const onInvoiceClientClick = () => {
    setIsInvoiceClientDrawerOpen(true);
    
  };

  const onInvoiceVendorClick = () => {
    setIsInvoiceVendorDrawerOpen(true);
    
  }


  const onSearch = (value) => {
    const filterdata = { "filters": { "name__icontains": value } }
    dispatch(getSurveyList(filterdata));
  }



  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      
      render: (data, record) => (
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="round"
            size={30}
            src={record.ui_icon}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}><Space>{data}<Popover content="Edit Details"><EditTwoTone onClick={() => onEditClick(record)} /></Popover><Popover content="Filter Users"><FilterTwoTone onClick={() => onFilterClick(record)}/></Popover></Space></Title>
            <p>{record.ui_title}</p>
            <p>{record.ui_description}</p>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, record)=>(
        <Space>
        <p>{record.status}</p>
        {["CLOSED", "INVOICE", "PAID"].includes(record.status) &&
          <>
            <UpSquareTwoTone onClick={onInvoiceClientClick}/>
            <DownSquareTwoTone onClick={onInvoiceVendorClick}/>
          </>
        }
        </Space>
      )
    },
   
    
    {
      title: "Added on",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, record) => (
        <>
          <div>{record.created_at}</div>
          <div>{record.updated_at}</div>
        </>
      )
    },
    
  ];

  return (
    <>
      <div className="tabled">
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title="Surveys Details"
          extra={
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ color: 'white', zIndex: 10 }}
              onClick={onAddClick}
            >
              Add Survey
            </Button>
          }
        >
          <Row >
            <Col span={8}>
              <div style={{ marginLeft: '5%' }}>
                <Search placeholder="Search By Name" onSearch={onSearch} enterButton />
              </div>
            </Col>
          </Row>
          <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={surveys}
              pagination={true}
              loading={fetching}
              rowKey={(record) => record.id}
              className="ant-border-space"
            />
          </div>
        </Card>
      </div>
      {isEditableDrawerOpen && (
        <EditSurveyDrawer
          setIsEditableDrawerOpen={setIsEditableDrawerOpen}
          particularSurveyInformation={particularSurveyInformation}
          reloadSurveys={reloadSurveys}
        />
      )}
       {isCampaignDrawerOpen && (
        <CampaignDrawer
          isCampaignDrawerOpen={isCampaignDrawerOpen}
          setIsCampaignDrawerOpen={setIsCampaignDrawerOpen}
          particularCampaignInformation={particularCampaignInformation}
        />
      )}
      {isInvoiceClientDrawerOpen && (
        <InvoiceClientDrawer
          isInvoiceClientDrawerOpen={isInvoiceClientDrawerOpen}
          setIsInvoiceClientDrawerOpen={setIsInvoiceClientDrawerOpen}
          
          
        />
      )}
      {isInvoiceVendorDrawerOpen && (
        <InvoiceVendorDrawer
          isInvoiceVendorDrawerOpen={isInvoiceVendorDrawerOpen}
          setIsInvoiceVendorDrawerOpen={setIsInvoiceVendorDrawerOpen}
          
        />
      )}
    </>
  );

      }
export default SurveyCampaign;

