import {
    VENDOR_DATA_ERROR,
    VENDOR_DATA_REQUESTED,
    VENDOR_DATA_SUCCESS,
    VENDOR_DATA_SUBMIT_INITIATED,
    VENDOR_DATA_SUBMIT_SUCCESS,
    VENDOR_DATA_SUBMIT_ERROR
  } from "../actions/vendorActions/actionTypes";
  
  const INITIAL_STATE = {
    vendors: [],
    fetching: false,
    error: null,
    submiting: false,
    submitingError: null,
  };
  
  const vendorReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case VENDOR_DATA_REQUESTED:
        return {
          ...state,
          fetching: true,
          error: null,
        };
      case VENDOR_DATA_SUCCESS:
        return {
          ...state,
          vendors: payload,
          fetching: false,
        };
      case VENDOR_DATA_ERROR:
        return {
          ...state,
          fetching: false,
          error: payload,
        };
      case VENDOR_DATA_SUBMIT_INITIATED:
        return {
          ...state,
          submiting: true,
        };
        
        case VENDOR_DATA_SUBMIT_SUCCESS:
          return {
            ...state,
            vendors: [],
            submiting: false,
          };
      case VENDOR_DATA_SUBMIT_ERROR:
        return {
          ...state,
          submiting: false,
          error: payload,
        };
      default:
        return state;
    }
  };
  
  export default vendorReducer;