import {
    SURVEY_DATA_ERROR,
    SURVEY_DATA_REQUESTED,
    SURVEY_DATA_SUCCESS,
    SURVEY_DATA_SUBMIT_INITIATED,
    SURVEY_DATA_SUBMIT_SUCCESS,
    SURVEY_DATA_SUBMIT_ERROR,
    SURVEY_INVOIVE_VENDOR_DATA_REQUESTED,
    SURVEY_INVOIVE_VENDOR_DATA_SUCCESS,
    SURVEY_INVOIVE_VENDOR_DATA_ERROR,
    SURVEY_INVOIVE_CLIENT_DATA_REQUESTED,
    SURVEY_INVOIVE_CLIENT_DATA_SUCCESS,
    SURVEY_INVOIVE_CLIENT_DATA_ERROR,
    FILTER_DATA_REQUESTED,
    FILTER_DATA_SUCCESS,
    FILTER_DATA_ERROR,
    USER_COUNT_SUBMIT_INITIATED,
    USER_COUNT_SUBMIT_SUCCESS,
    USER_COUNT_SUBMIT_ERROR,
    NOTIFY_USER_SUBMIT_INITIATED,
    NOTIFY_USER_SUBMIT_SUCCESS,
    NOTIFY_USER_SUBMIT_ERROR
  } from "../actions/surveyActions/actionTypes";
  
  const INITIAL_STATE = {
    surveys: [],
    userCount:0,
    filterData:{},
    clientInvoice:{},
    vendorInvoice:{},
    noficationMessage:"",
    fetching: false,
    error: null,
    submiting: false,
    submitingError: null,
  };
  
  const surveyReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case SURVEY_DATA_REQUESTED:
        return {
          ...state,
          fetching: true,
          error: null,
        };
      case SURVEY_DATA_SUCCESS:
        return {
          ...state,
          surveys: payload,
          fetching: false,
        };
      case SURVEY_DATA_ERROR:
        return {
          ...state,
          fetching: false,
          error: payload,
        };
      case SURVEY_DATA_SUBMIT_INITIATED:
        return {
          ...state,
          submiting: true,
        };
        
        case SURVEY_DATA_SUBMIT_SUCCESS:
          return {
            ...state,
            surveys: [],
            submiting: false,
          };
      case SURVEY_DATA_SUBMIT_ERROR:
        return {
          ...state,
          submiting: false,
          error: payload,
        };

        case SURVEY_INVOIVE_CLIENT_DATA_REQUESTED:
          return {
            ...state,
            fetching: true,
            error: null,
          };
        case SURVEY_INVOIVE_CLIENT_DATA_SUCCESS:
          return {
            ...state,
            clientInvoice: payload,
            fetching: false,
          };
        case SURVEY_INVOIVE_CLIENT_DATA_ERROR:
          return {
            ...state,
            fetching: false,
            error: payload,
          };
          case SURVEY_INVOIVE_VENDOR_DATA_REQUESTED:
            return {
              ...state,
              fetching: true,
              error: null,
            };
          case SURVEY_INVOIVE_VENDOR_DATA_SUCCESS:
            return {
              ...state,
              vendorInvoice: payload,
              fetching: false,
            };
          case SURVEY_INVOIVE_VENDOR_DATA_ERROR:
            return {
              ...state,
              fetching: false,
              error: payload,
            };
            case  USER_COUNT_SUBMIT_INITIATED:
        return {
          ...state,
          submiting: true,
        };
        
        case  USER_COUNT_SUBMIT_SUCCESS:
          return {
            ...state,
            userCount: payload,
            submiting: false,
          };
      case  USER_COUNT_SUBMIT_ERROR:
        return {
          ...state,
          submiting: false,
          error: payload,
        };

        case  NOTIFY_USER_SUBMIT_INITIATED:
          return {
            ...state,
            submiting: true,
          };
          
          case  NOTIFY_USER_SUBMIT_SUCCESS:
            return {
              ...state,
              notificationMessage: payload,
              submiting: false,
            };
        case  NOTIFY_USER_SUBMIT_ERROR:
          return {
            ...state,
            submiting: false,
            error: payload,
          };

        case FILTER_DATA_REQUESTED:
          return {
            ...state,
            fetching: true,
            error: null,
          };
        case FILTER_DATA_SUCCESS:
          return {
            ...state,
            filterData: payload,
            fetching: false,
          };
        case FILTER_DATA_ERROR:
      default:
        return state;

    }
  };
  
  export default surveyReducer;