import axios from "axios";

export const getIpDetails = async () => {
  const response = await axios.get("https://api.ipify.org/?format=json");
  const ipdetails = await axios.get("https://ipapi.co/" + response.data["ip"] + "/json/");
  localStorage.setItem("ip_details", JSON.stringify(ipdetails.data));
  return ipdetails.data;
}

export const continuumLogin = async (provider, data) => {
  let ipDetail = localStorage.getItem("ip_details")
  if (ipDetail == undefined || ipDetail == null) {
    ipDetail = '{}'
  }
  ipDetail = JSON.parse(ipDetail)
  const response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/v1/api/identity/login", { "provider": provider, "data": data, "ip_data": ipDetail});
  if (response.data.data.token) {
    localStorage.setItem("token", JSON.stringify(response.data.data.token));
    localStorage.setItem("user", JSON.stringify(response.data.data.user));
  }
  return response.data.data.user;
};

export const continuumLogout = () => {
  localStorage.removeItem("user");
};
